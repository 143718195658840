import Element from "@/models/Element";
import Page from "@/models/Page";

export interface RandomizationItems {
  [ref: string]: {
    configuration: number
  }
}
export enum RandomizationMethod {
  Shuffle = 'shuffle',
  None = 'none'
}

export class Randomization {
  constructor(public items: RandomizationItems = {},
              public methods: RandomizationMethod[] = [RandomizationMethod.Shuffle]) {}

  getRandomizedItems(): [string, {configuration: number}][] {
    return Object.entries(this.items).filter(([_key, value]) => value.configuration !== null);
  }
  hasRandomizedItems(): boolean {
    return this.getRandomizedItems().length > 0;
  }

  static clearRandomization(element: Element) {
    if (element instanceof Page) {
      element.nodeRandomization = new Randomization();
    }
  }

  static setRandomization(element: Element, lockedRefs: {[ref: string]: boolean}) {
    if (element instanceof Page) {
      this.setPageRandomization(element, lockedRefs);
    }
  }

  private static setPageRandomization(page: Page, lockedRefs: {[ref: string]: boolean}) {
    const items = page.nodes
      .filter(node => !lockedRefs[node.ref])
      .reduce<RandomizationItems>((acc, node, index) => ({ ...acc, [node.ref]: { configuration: index + 1} }), {});
    page.nodeRandomization = new Randomization(items);
  }

  static getLockedItems(element: Element) {
    if (element instanceof Page) {
      return this.getPageLockedItems(element);
    }
  }

  private static getPageLockedItems(page: Page): {[ref: string]: boolean} {
    const lockedItems = Object.entries(page.nodeRandomization.items)
      .reduce((acc, [ref, value]) => value.configuration === null ? {...acc, [ref]: true} : acc, {});
   return page.nodes.reduce((acc, node) => {
     if (!(page.nodeRandomization.items[node.ref]?.configuration > 0)) {
       return {...acc, [node.ref]: true};
     }
     return acc;
   }, lockedItems);
  }
}
