import Node, {
  NodeTypeId,
  QUESTION_2D_TYPES,
  QUESTION_CHOICE_TYPES,
  QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS
} from "./Node";
import {cloneDeep} from "@/utils/lodash";
import {uuid} from "@/utils/string";
import {createNode} from "@/utils/nodeTypeFactory";
import OptionSet from "@/models/OptionSet";
import Option from "@/models/Option";
import Survey from "@/models/Survey";
import QuestionType1d, {OptionSetProperty} from "@/models/nodeTypes/QuestionType1d";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import Element, {BaseElementParams, ElementType} from "@/models/Element";
import QuestionTypeSingleSelect from "@/models/nodeTypes/QuestionTypeSingleSelect";
import QuotaGroup from "@/models/QuotaGroup";
import i18n from "@/plugins/i18n";
import {Ref, Referable} from "@/typings/global-types";
import SurveyError, {SurveyErrorProperty, SurveyErrorType} from "@/models/errors/SurveyError";
import {Randomization} from "@/models/Randomization";

export default class Page extends Element implements Referable {
  static readonly DEFAULT_RULE = null;
  static readonly DEFAULT_ACTIONS = {
    nextDelay: {
      seconds: null,
    }
  }
  static readonly DEFAULT_NEXT_DELAY = null;

  nodes: Array<Node> = [];
  title: string;
  actions: Actions = Page.DEFAULT_ACTIONS;
  _collapsed: boolean = false;
  nodeRandomization: Randomization;

  constructor(
    baseElementParams: BaseElementParams,
    nodes: Array<Node> = [],
    title: string = '',
    actions: Actions = Page.DEFAULT_ACTIONS,
    collapsed: boolean = false,
    randomization: Randomization = new Randomization()
  ) {

    super(baseElementParams);
    this.type = ElementType.Page;
    this._collapsed = collapsed;

    this.title = title ?? ''; //TODO: this is required for accessibility, confirm if we want to use it.

    this.addActions(actions ?? Page.DEFAULT_ACTIONS)
    this.addNodes(nodes ?? [], baseElementParams.survey);
    this.nodeRandomization = randomization ? new Randomization(randomization.items, randomization.methods) : new Randomization();
  }

  validate(survey: Survey) {
    this.validateNodesLength(survey);
  }

  validateNodesLength(survey: Survey) {
    survey.resetSurveyErrors(this.ref, null, [SurveyErrorProperty.PageNodes], [SurveyErrorType.Empty]);
    if (!this.nodes.length) {
      survey.addSurveyError(new SurveyError(SurveyErrorType.Empty, SurveyErrorProperty.PageNodes, this.ref));
    }
  }

  addNode(
    node: Node,
    nodeIndex: number = null,
    survey: Survey = null,
    duplicate: boolean = false,
    duplicateFromSurvey: Survey = null,
    duplicateQuestionQuotaGroup: boolean = false,
    duplicateMasking: boolean = true,
    duplicatePipes: boolean = true,
    duplicateRefs: boolean = true,
  ): Node {
    const nodeCopy = duplicate ? cloneDeep(node) : node;
    const duplicateSet1OptionsMap: Map<string, string> = new Map(); // key = new option ref; value = original option ref
    let currentLockedItems: {[p: string]: boolean};
    if (this.nodeRandomization?.hasRandomizedItems()) { //Avoids altering randomization object on page creation phase.
      currentLockedItems = Randomization.getLockedItems(this);
    }
    duplicateFromSurvey = duplicateFromSurvey ?? survey;

    if (duplicate) {
      //duplicate option set(s)
      //TODO: modify in future to either duplicate or maintain same set (when standard sets supported)
      if (node instanceof QuestionType1d) {
        if (!duplicatePipes) {
          duplicateFromSurvey.replaceQuestionPipesWithGenericInsert(nodeCopy);
        }

        if (!duplicateMasking) {
          nodeCopy.optionMasking = {};
        }

        for (let setNum = 1; setNum <= node.constructor['NUM_SETS']; setNum++) {
          const setProperty = 'set' + setNum as OptionSetProperty;
          if (setProperty === 'set2' && !(node instanceof QuestionType2d)) {
            continue;
          }

          const optionSet = cloneDeep(duplicateFromSurvey.getOptionSetByRef(node[setProperty]));
          const newOptions: Option[] = [];
          (optionSet.options as Option[]).forEach(option => {
            // update option config
            const newOptionRef = duplicateRefs ? uuid() : option.ref;
            const newOption = cloneDeep(option) as Option;
            newOption.ref = newOptionRef;
            newOptions.push(newOption);

            nodeCopy.optionConfigurations[newOptionRef] = cloneDeep(node.optionConfigurations[option.ref]);

            // save option ref to duplicate quotas map
            if (duplicateQuestionQuotaGroup && duplicateRefs && setProperty === 'set1') {
              duplicateSet1OptionsMap.set(newOption.ref, option.ref);
            }

            // remove option masking, if required
            if (!duplicateMasking && node.optionConfigurations[option.ref].parentRef !== null) {
              if (option.label === i18n.global.t('question.questionSpecifyPipe')) {
                // set specify pipe to original label and specify config to true
                const maskParentSurvey = duplicateFromSurvey ?? survey;
                const parentOption = node.getParentOfMaskedOption(option, maskParentSurvey, setProperty);
                newOption.label = parentOption?.label ?? newOption.label;
                newOption.specify = true;
              }

              nodeCopy.optionConfigurations[newOptionRef].parentRef = null;
            }
          })

          nodeCopy[setProperty] = duplicateRefs ? uuid() : nodeCopy[setProperty];
          const newOptionSet = new OptionSet(nodeCopy[setProperty], optionSet.order ?? null, newOptions);
          survey.addOptionSet(newOptionSet);
        }
      }
    }

    const createNewNode = survey && (!(node instanceof Node) || duplicate);
    const addQuotaGroup = QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS.includes(node.type) && (duplicateQuestionQuotaGroup || !(node instanceof Node)); // only if indicated or on survey load
    const newNode = !createNewNode ? nodeCopy : createNode(
      {
        type: nodeCopy.type,
        survey: survey,
        ref: (duplicate && duplicateRefs) ? uuid() : nodeCopy.ref ?? null,
        code: nodeCopy.code ?? null,
        name: nodeCopy.name ?? null,
        text: nodeCopy.text ?? null,
        rule: nodeCopy.rule ?? null,
        hide: nodeCopy.hide ?? null,
        require: nodeCopy.require ?? null,
        assets: nodeCopy.assets ?? null,
      },
      nodeCopy.ordinalities ?? null,
      {...(nodeCopy.i18n ?? {})},
      {
        set1: nodeCopy.set1 ?? null,
        set2: nodeCopy.set2 ?? null,
        optionConfigurations: nodeCopy.optionConfigurations ?? null,
        optionMasking: nodeCopy.optionMasking ?? null,
        quotaGroupRef: null, // added below
      }
    )

    // Quota group
    if (addQuotaGroup && nodeCopy.quotaGroupRef) {
      const quotaGroup = newNode instanceof QuestionType1d
        ? duplicate
          ? duplicateFromSurvey.getQuotaByRef(nodeCopy.quotaGroupRef) as QuotaGroup
          : survey.getQuotaByRef(nodeCopy.quotaGroupRef) as QuotaGroup
        : null;

      if (quotaGroup instanceof QuotaGroup) {
        survey.addQuestionQuotaGroup(newNode as QuestionType1d, quotaGroup, duplicateQuestionQuotaGroup && duplicateRefs ? duplicateSet1OptionsMap : null);
      }
    }

    if (nodeIndex !== null && typeof this.nodes[nodeIndex] !== 'undefined') {
      this.nodes.splice(nodeIndex, 0, newNode);
    } else {
      this.nodes.push(newNode);
    }

    //Randomization
    if (this.nodeRandomization?.hasRandomizedItems()){ //Avoids altering randomization object on page creation phase.
      if (duplicate && currentLockedItems[node.ref]) {
        currentLockedItems[newNode.ref] = true; // keeps the same randomization status (randomized or locked) for duplicated items
      }
      Randomization.setRandomization(this, currentLockedItems);
    }

    return newNode;
  }

  addNodes(
    nodes: Array<Node>,
    survey: Survey,
    duplicate: boolean = false,
    duplicateFromSurvey: Survey = null,
    duplicateQuestionQuotaGroup: boolean = false,
    duplicateMasking: boolean = true,
    duplicatePipes: boolean = true,
    duplicateRefs: boolean = true,
  ) {
    nodes.forEach(node => {
      this.addNode(node, null, survey, duplicate, duplicateFromSurvey, duplicateQuestionQuotaGroup, duplicateMasking, duplicatePipes, duplicateRefs);
    })
  }

  deleteNode(node: Node, survey: Survey) {
    const currentLockedItems = Randomization.getLockedItems(this);

    if (node instanceof QuestionType1d) {
      // delete any masking
      node.deleteMaskingFromChildren(survey, 'set1');

      if (node instanceof QuestionType2d) {
        node.deleteMaskingFromChildren(survey, 'set2');
      }

      // delete option sets
      for (let setNum = 1; setNum <= node.constructor['NUM_SETS']; setNum++) {
        const setProperty = 'set' + setNum as OptionSetProperty;
        if (setProperty === 'set2' && !(node instanceof QuestionType2d)) {
          continue;
        }

        const optionSet = survey.getOptionSetByRef(node[setProperty]);
        survey.deleteQuestionQuotaGroup(node);
        survey.deleteOptionSetIfNotInUse(optionSet, node, false, setProperty);
      }
    }

    this.nodes.splice(this.getNodeIndex(node), 1);

    if (this.nodeRandomization?.hasRandomizedItems()) {
      Randomization.setRandomization(this, currentLockedItems);
    }

    survey.resetSurveyErrors(null, node.ref);

    survey.handleElementPositionChange();
  }

  duplicateNode(node: Node, survey: Survey, name: string = null, nodeIndex: number = null): Node {
    node = cloneDeep(node);
    if (name !== null) {
      node.name = name;
    }
    return this.addNode(node, nodeIndex, survey, true);
  }

  changeNodeType(newType: NodeTypeId, survey: Survey, nodeIndex = 0): boolean {
    const currentNode = this.nodes[nodeIndex] ?? null;

    if (!currentNode || currentNode.type === newType) {
      return false;
    }

    const newTypeHasSets = QUESTION_CHOICE_TYPES.includes(newType);
    const isNewType2d = QUESTION_2D_TYPES.includes(newType);

    const isCurrentInstanceOf1d = currentNode instanceof QuestionType1d;
    const isCurrentInstanceOf2d = currentNode instanceof QuestionType2d;

    // delete option sets and masking, if not applicable to new type
    if (isCurrentInstanceOf1d) {
      if (!newTypeHasSets) {
        const set1 = survey.getOptionSetByRef(currentNode.set1);
        (currentNode as QuestionType1d).deleteMaskingFromChildren(survey, 'set1');
        survey.deleteOptionSetIfNotInUse(set1, currentNode, true, 'set1');
      }

      if (isCurrentInstanceOf2d && !isNewType2d) {
        const set2 = survey.getOptionSetByRef(currentNode.set2);
        (currentNode as QuestionType2d).deleteMaskingFromChildren(survey, 'set2');
        survey.deleteOptionSetIfNotInUse(set2, currentNode, true, 'set2');
      }
    }

    const baseNodeParams = {
      type: newType,
      survey: survey,
      code: currentNode.code,
      ref: currentNode.ref,
      name: currentNode.name,
      text: currentNode.text,
      rule: currentNode.rule,
      hide: currentNode.hide,
      require: currentNode.require,
    }

    // option sets and ordinalities are the only properties that could be different between node types
    // the type class will take care of setting any matching ordinalities, else will set to the defaults of the new type
    // it will also create any option set(s) as required
    const ordinalities = cloneDeep(currentNode.ordinalities);
    const i18n = currentNode.i18n;
    const optionSetParams = newTypeHasSets
      ?  {
          set1: isCurrentInstanceOf1d ? currentNode.set1 : null,
          set2: isCurrentInstanceOf2d ? currentNode.set2 : null,
          optionConfigurations: isCurrentInstanceOf1d ? currentNode.optionConfigurations : {},
          optionMasking: isCurrentInstanceOf1d ? currentNode.optionMasking : {},
          quotaGroupRef: currentNode instanceof QuestionTypeSingleSelect ? currentNode.quotaGroupRef : null, // TODO: assume if still a single select, keep the quotas, but should warn otherwise?
        }
      : null;

    const newNode = createNode(baseNodeParams, ordinalities, i18n, optionSetParams);
    this.nodes[nodeIndex] = newNode;

    survey.handleNodeTypeChange(newNode);

    return true;
  }

  getNodeIndex(node: Node): number {
    return this.nodes.findIndex(el => el.ref === node.ref);
  }

  addActions(actions: Actions) {
    const nextDelay = actions.nextDelay?.seconds ?? Page.DEFAULT_NEXT_DELAY;
    this.actions = {
      nextDelay: {
        seconds: !isNaN(parseInt(nextDelay))
            ? parseInt(nextDelay)
            : Page.DEFAULT_NEXT_DELAY
      }
    }
  }

  getRefs(): Array<Ref> {
    const refs = super.getRefs()

    refs.push(...this.nodes.map((node: Node) => node.getRefs()).flat())

    return refs
  }

  getAllNodeLabels(maxLabelLength = 30): string {
    return this.nodes.map(node =>
      node.getCleanTitle(false, this.nodes.length > 1 ? maxLabelLength : null),
    ).join(", ");
  }

  getCodeLabel(): string {
    return super.getCodeLabel('page.initial');
  }
}

export interface Actions {
  nextDelay: {
    seconds: number | null
  }
}
