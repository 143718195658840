import Page from "@/models/Page";
import Node, {
  NodeTypeId,
  QUESTION_2D_TYPES,
  QUESTION_CHOICE_TYPES,
  QUESTION_TYPES,
  QUESTION_TYPES_SUPPORTED_PIPING,
  QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS,
  QUESTION_TYPES_SUPPORTED_ROUTING,
} from "@/models/Node";
import OptionSet from "@/models/OptionSet";
import {stripHTML, uuid} from "@/utils/string";
import QuestionType1d, {OptionSetProperty} from "@/models/nodeTypes/QuestionType1d";
import QuestionType2d from "@/models/nodeTypes/QuestionType2d";
import RuleGroup from "@/models/RuleGroup";
import RuleSet, {RuleSetGoToStatus, RuleSetGoToType, RuleSetGoToProperty} from "@/models/RuleSet";
import Element, {ElementType} from "@/models/Element";
import Option from "@/models/Option";
import {HTML_TAG_REGEX, QUESTION_CODE_REGEX, QUESTION_CODE_WITH_HTML_TAGS_REGEX} from "@/constants/questions";
import i18n from "@/plugins/i18n";
import Rule, {RuleChoiceValue, RuleOperator} from "@/models/Rule";
import {CannotDeleteDefaultLocaleTranslationsError, Locale, TranslationData, TranslationFile} from "./translation";
import Quota from "@/models/Quota";
import QuotaGroup from "@/models/QuotaGroup";
import Asset from "./Asset";
import {MapAssets} from "@/composables/api/questionsService";
import webBridge from '@maru/shell-app'
import SurveyError, {
  RULE_SET_ERROR_PROPERTIES,
  SurveyErrorProperty,
  SurveyErrorType
} from "@/models/errors/SurveyError";
import {Ref} from "@/typings/global-types";
import {Randomization} from "@/models/Randomization";

const { t } = i18n.global

export enum SurveyChangeAction {
  DeleteElement = 'delete-element',
  AddElement = 'add-element',
  MoveElement = 'move-element',
}

export interface SurveyData {
  elements: Array<any>,
  sets: Array<any>,
  quotas: Array<any>,
  locale: string,
  locales: Array<string>,
}

export default class Survey {
  elements: Array<Element> = [];
  sets: Array<OptionSet> = [];
  quotas: Array<Quota | QuotaGroup> = [];
  locale: string = null;
  locales: Array<string> = [];
  errors: SurveyError[];

/* TODO - deployment(?)
  //deployment could be taken care of by glue app for now, but it will be needed in UI for other sample sources in future and could be more complex
  // when have multiple different sources w/ different redirects so likely a future concern that will need some thought and updates in QApp in future.
  deployment: Deployment;
  deployment: {
    success: { redirect: "https://s.cint.com/survey/return/%var(externalID)%", message: "you completed" },
    dq: { redirect: "https://s.cint.com/survey/return/%var(externalID)%", message: null },
    oq: { redirect: null, message: "you oq'd" },
  }
  - often has message and/or redirect...though QApp currently only supports a redirect and if not provided, it's a fallback message.
*/

  constructor(elements: Array<Element> = [], sets: Array<OptionSet> = [], quotas: Array<Quota | QuotaGroup> = [], locale: string = null, locales: Array<string> = null, validateSurvey: boolean = true) {
    this.errors = [];

    this.addOptionSets(sets ?? []);
    this.addQuotas(quotas ?? []);
    this.addElements(elements ?? []);

    this.locale = locale ? new Locale(locale).ISO15897Code : Locale.default.ISO15897Code;
    this.locales = locales?.map(locale => new Locale(locale).ISO15897Code) || [this.locale];

    if (validateSurvey) {
      this.validateSurvey(true);
    }
  }

  static fromData(survey: Partial<SurveyData>|null, validateSurvey: boolean = true): Survey {
    return new Survey(
      survey?.elements ?? null,
      survey?.sets ?? null,
      survey?.quotas ?? null,
      survey?.locale ?? null,
      survey?.locales ?? null,
      validateSurvey
    );
  }

  clone(): Survey {
    return new Survey(
      this.elements,
      this.sets,
      this.quotas,
      this.locale,
      this.locales,
      !!this.errors.length
    )
  }

  validateSurvey(validateMaskedOptions: boolean = false, excludeRuleSets: RuleSet[] = [], excludePages: Page[] = [], excludeNodes: Node[] = []) {
    this.validateNodes(validateMaskedOptions, null, null, excludeNodes);
    this.validatePages(null, excludePages);
    this.validateLogic(false, null, excludeRuleSets);
    this.validateOptionSets();
  }

  /*********************************************************************************************************************/
  /* OPTION SETS */
  /*********************************************************************************************************************/

  validateOptionSets() {
    // remove any option sets not attached to a question
    const linkedOptionSetRefs = new Set<Ref>();

    this.getAllNodes().forEach(node => {
      if (node instanceof QuestionType1d) {
        linkedOptionSetRefs.add(node.set1);

        if (node instanceof QuestionType2d) {
          linkedOptionSetRefs.add(node.set2);
        }
      }
    });

    this.sets.forEach(set => {
      if (!linkedOptionSetRefs.has(set.ref)) {
        this.deleteOptionSet(set);
      }
    })
  }

  addOptionSet(set: OptionSet) {
    this.sets.push(set);
  }

  addOptionSets(sets: Array<OptionSet>) {
    sets.forEach(set => {
      this.sets.push(
        new OptionSet(
          set.ref,
          set.order ?? null,
          set.options ?? null
        )
      )
    })
  }

  deleteOptionSetIfNotInUse(optionSet: OptionSet, fromNode: QuestionType1d, deleteQuestionOptionConfig: boolean = true, optionSetProperty: OptionSetProperty = 'set1') {
    if (deleteQuestionOptionConfig) {
      optionSet.options.forEach((option: Option) => {
        fromNode.deleteQuestionOptionConfiguration(option.ref)
      })
    }

    const quotaGroup = fromNode.getQuestionQuotaGroup(this);
    if (quotaGroup) {
      this.deleteQuestionQuotaGroup(fromNode);
    }

    //TODO: currently it's not possible to have shared option sets in the UI so hardcoding to false for now
    // will need to address impact in masking, etc. when we support this and where the set is removed from one question only
    const optionSetInUse = false; //!this.isOptionSetInUse(optionSet, fromNode)
    if (!optionSetInUse) {
      this.deleteOptionSet(optionSet, fromNode, false, optionSetProperty);
    }

    fromNode[optionSetProperty] = null;
  }

  deleteOptionSet(optionSet: OptionSet, fromNode: QuestionType1d = null, deleteQuestionOptionConfig = true, optionSetProperty: OptionSetProperty = 'set1') {
    const optionSetIndex = this.getOptionSetIndex(optionSet);
    if (optionSetIndex > -1) {
      if (deleteQuestionOptionConfig) {
        this.elements.forEach(element => {
          if (element instanceof Page) {
            element.nodes.forEach(node => {
              if (node instanceof QuestionType1d) {
                optionSet.options.forEach((option: Option) => {
                  node.deleteQuestionOptionConfiguration(option.ref)
                })
              }
            })
          }
        })
      }

      // delete options first to ensure trigger of logic checks, etc.
      optionSet.deleteOptions(this, fromNode, null, optionSetProperty);

      this.sets.splice(optionSetIndex, 1);
    }

    this.resetSurveyErrors(optionSet.ref);
  }

  isOptionSetInUse(optionSet: OptionSet, excludeNode?: QuestionType1d): boolean {
    if (optionSet) {
      for (const element of this.elements) {
        //check each node on page
        if (element instanceof Page) {
          for (const currentNode of element.nodes) {
            if ((excludeNode && currentNode.ref !== excludeNode.ref) && currentNode instanceof QuestionType1d) {
              const isSameSetRef = currentNode instanceof QuestionType1d && currentNode.set1 === optionSet.ref
                || currentNode instanceof QuestionType2d && currentNode.set2 === optionSet.ref;

              if (isSameSetRef) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  getOptionSetByRef(ref: string): OptionSet | null {
    return this.sets.find(el => el.ref === ref) ?? null;
  }

  getOptionSetByOptionRef(optionRef: Ref): OptionSet | null {
    const optionSet = this.sets.find(set => {
      return !!set.options.find(option => option.ref === optionRef);
    })

    return optionSet ?? null;
  }

  getOptionSetIndex(optionSet: OptionSet): number {
    return this.sets.findIndex(el => el.ref === optionSet.ref);
  }

  getAllOptions(): Option[] {
    return this.sets.flatMap(set => set.options);
  }

  getOptionByRef(ref: string): Option | null {
    return this.getAllOptions().find(option => option.ref === ref) ?? null;
  }

  getOptionSetsByNode(node: Node): OptionSet[] {
    const sets = [] as OptionSet[];

    if (node instanceof QuestionType1d) {
      for (let setNum = 1; setNum <= node.constructor['NUM_SETS']; setNum++) {
        const setProperty = 'set' + setNum;
        if (setProperty === 'set2' && !(node instanceof QuestionType2d)) {
          continue;
        }

        sets.push(this.getOptionSetByRef(node[setProperty]));
      }
    }

    return sets;
  }

  /*********************************************************************************************************************/
  /* ELEMENTS */
  /*********************************************************************************************************************/
  addElement(element: Element, index: number = null, validateLogic: boolean = true): Element {
    if (index !== null && typeof this.elements[index] !== 'undefined') {
      this.elements.splice(index, 0, element);
    } else {
      this.elements.push(element);
    }

    if (validateLogic) {
      this.validateLogicElseGoToOnChange(SurveyChangeAction.AddElement, element);
    }

    return element;
  }

  addElements(elements: Array<Element>): Array<Element> {
    elements.forEach(element => {
      let typedElement;
      const baseElementParams = {
        survey: this,
        ref: element.ref ?? null,
        code: element.code ?? null,
        name: element.name ?? null,
        hide: element.hide ?? null,
      };

      switch (element.type) {
        case ElementType.Page:
          typedElement = element as Page;
          this.elements.push(
            new Page(
              baseElementParams,
              typedElement.nodes ?? null,
              typedElement.title ?? null,
              typedElement.actions ?? null,
              false,
              typedElement.nodeRandomization ?? null
            )
          )
          break;
        case ElementType.RuleSet:
          typedElement = element as RuleSet;
          this.elements.push(
            new RuleSet(
              baseElementParams,
              typedElement.goToType ?? null,
              typedElement.goTo ?? null,
              typedElement.elseGoToType ?? null,
              typedElement.elseGoTo ?? null,
              new RuleGroup(
                this,
                typedElement.ruleGroup.ref ?? null,
                typedElement.ruleGroup.type ?? null,
                typedElement.ruleGroup.rules ?? null
              )
            )
          )
          break;
        default:
          break;
      }
    })
    return this.elements;
  }

  deleteElement(element: Element) {
    switch (true) {
      case element instanceof Page:
        this.deletePage(element as Page);
        break;
      case element instanceof RuleSet:
        this.deleteRuleSet(element as RuleSet);
        break;
      default:
        break;
    }
  }

  importElement(
    element: Element,
    index: number,
    name: string = null,
    importFromSurvey: Survey = null,
    importQuestionQuotaGroup: boolean = false,
    importMasking: boolean = false,
    importPipes: boolean = false,
    duplicateRefs: boolean = false,
  ): Element {
    return this.duplicateElement(element, index, name, importFromSurvey, importQuestionQuotaGroup, importMasking, importPipes, duplicateRefs);
  }

  duplicateElement(
    element: Element,
    index: number,
    name: string = null,
    duplicateFromSurvey: Survey = null,
    duplicateQuestionQuotaGroup: boolean = false,
    duplicateMasking: boolean = true,
    duplicatePipes: boolean = true,
    duplicateRefs: boolean = true,
  ): Element {
    const baseElementParams = {
      survey: this,
      ref: duplicateRefs ? uuid() : element.ref,
      code: null,
      name: name ?? element.name,
      hide: element.hide ?? null,
    }

    let newElement;

    switch(true) {
      case element instanceof Page:
        newElement = new Page(baseElementParams, [], (element as Page).title ?? null, (element as Page).actions ?? null) as Page;
        newElement.addNodes((element as Page)?.nodes ?? [], this, true, duplicateFromSurvey, duplicateQuestionQuotaGroup, duplicateMasking, duplicatePipes, duplicateRefs);
        break;
      case element instanceof RuleSet:
        newElement = new RuleSet(
          baseElementParams,
          (element as RuleSet).goToType ?? null,
          (element as RuleSet).goTo ?? null,
          (element as RuleSet).elseGoToType ?? null,
          (element as RuleSet).elseGoTo ?? null,
          (element as RuleSet).ruleGroup ?? null
        ) as RuleSet;

        // update to unique rule IDs, if required
        if (duplicateRefs) {
          newElement.ruleGroup.ref = uuid();
          newElement.ruleGroup.rules.forEach(rule => rule.ref = uuid());
        }
    }

    return this.addElement(newElement, index, false);
  }

  getElementByRef(ref: string): Element | null {
    return this.elements.find(el => el.ref === ref ?? null);
  }

  getElementByIndex(index: number): Element | null {
    return this.elements[index] ?? null;
  }

  getElementIndex(element: Element): number {
    return this.getElementIndexByRef(element.ref);
  }

  getElementIndexByRef(ref: string): number {
    return this.elements.findIndex(el => el.ref === ref);
  }

  getPreviousElementByType(currentElement: Element, type: ElementType): Element | null {
    const index = this.getElementIndex(currentElement) - 1;

    for (let i = index; i >= 0; i--) {
      if (this.elements[i].type === type) {
        return this.elements[i];
      }
    }

    return null;
  }

  getPreviousElementsByType(currentElement: Element, type: ElementType, includeCurrentElement: boolean = false, stopAtNewType: boolean = true): Element[] | null {
    const index = this.getElementIndex(currentElement) - +!includeCurrentElement;
    const elements = [];

    for (let i = index; i >= 0; i--) {
      if (this.elements[i].type === type) {
        elements.push(this.elements[i]);
      } else if (stopAtNewType) {
        break;
      }
    }

    return elements;
  }

  getNextElementByType(currentElement: Element, type: ElementType): Element | null {
    const index = this.getElementIndex(currentElement) + 1;

    for (let i = index; i < this.elements.length; i++) {
      if (this.elements[i].type === type) {
        return this.elements[i];
      }
    }

    return null;
  }

  getNextElementsByType(currentElement: Element, type: ElementType, includeCurrentElement = false, stopAtNewType = true): Element[] | null {
    const index = this.getElementIndex(currentElement) + +!includeCurrentElement;
    const elements = [];

    for (let i = index; i < this.elements.length; i++) {
      if (this.elements[i].type === type) {
        elements.push(this.elements[i]);
      } else if (stopAtNewType) {
        break;
      }
    }

    return elements;
  }

  moveElement(fromIndex: number, toIndex: number) {
    this.elements.splice(toIndex, 0, this.elements.splice(fromIndex, 1)[0]);
  }

  handleElementPositionChange(excludeRuleSets: RuleSet[] = [], excludePages: Page[] = [], excludeNodes: Node[] = []) {
    this.validateSurvey(false, excludeRuleSets, excludePages, excludeNodes);
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - PAGES  */
  /*********************************************************************************************************************/

  getAllPages(sliceBeforeIndex: number | null = null, sliceAfterIndex: number | null = null): Page[] {
    return this.elements.filter(element =>
      element instanceof Page
        && (sliceBeforeIndex !== null ? this.elements.indexOf(element) < sliceBeforeIndex : true)
        && (sliceAfterIndex  !== null ? this.elements.indexOf(element) > sliceAfterIndex  : true)
    ) as Page[];
  }

  deletePage(page: Page) {
    const pageIndex = this.getElementIndex(page);
    if (pageIndex > -1) {
      if (pageIndex > 0) {
        // update any rule elseGoTos first
        this.validateLogicElseGoToOnChange(SurveyChangeAction.DeleteElement, page)
      }

      // delete nodes + option sets first
      page.nodes.forEach(node => {
        page.deleteNode(node, this);
      })

      this.elements.splice(pageIndex, 1);
    }

    this.resetSurveyErrors(null, page.ref);

    this.handleElementPositionChange();
  }

  validatePages(specificPage: Page = null, excludePages: Page[] = []) {
    specificPage = specificPage ? this.getElementByRef(specificPage.ref) as Page : null;
    if (specificPage) {
      specificPage.validate(this);
    } else {
      const excludePageRefs = excludePages.map(page => page.ref);
      this.getAllPages().forEach((page, index) => {
        this.resetSurveyErrors(page.ref);

        page.code = index + 1; // just ensure they're numbered sequentially

        if (!excludePageRefs.includes(page.ref)) {
          page.validate(this);
        }
      });
    }
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - NODES / QUESTIONS */
  /*********************************************************************************************************************/

  getNodeByRef(nodeRef: string): Node | null {
    return this.getAllNodes().find(node => node.ref === nodeRef) ?? null;
  }

  getNodeIndexByRef(ref: string): number | null {
    const node = this.getNodeByRef(ref);
    return node ? this.getPageOfNode(node).nodes.findIndex(el => el.ref === node.ref) : null;
  }

  getNodeByCode(code: number | string, specificTypes: NodeTypeId[] = null): Node | null {
    return this.getAllNodes(specificTypes).find(node => node.code === code) ?? null;
  }

  getNodeByOption(optionRef: string): Node | null {
    const optionSet = this.getOptionSetByOptionRef(optionRef);
    if(!optionSet) {
      return null
    }

    const node = this.getAllNodes().find(node => {
        return (node instanceof QuestionType1d && node.set1 === optionSet.ref) ||
          (node instanceof QuestionType2d && (node.set1 === optionSet.ref || node.set2 === optionSet.ref))
      })
    return node ?? null;
  }

  getAllNodes(specificTypes: NodeTypeId[] = null, sliceBeforePageIndex: number|null = null, sliceAfterPageIndex: number|null = null): Node[] {
    const nodes = this.getAllPages(sliceBeforePageIndex, sliceAfterPageIndex).flatMap(element => (element as Page).nodes);

    return specificTypes ? nodes.filter(node => specificTypes.includes(node.type)) : nodes;
  }

  getAllNodesMaskedByNode(maskedFromNode: QuestionType1d | QuestionType2d): (QuestionType1d | QuestionType2d)[] {
    const nodes = this.getAllNodes(QUESTION_CHOICE_TYPES) as (QuestionType1d | QuestionType2d)[];

    const parentNodes = nodes.filter(node => {
      return (node?.optionMasking?.set1?.[0]?.parentNode === maskedFromNode.ref) || (node?.optionMasking?.set2?.[0]?.parentNode === maskedFromNode.ref);
    });

    let childNodes: (QuestionType1d | QuestionType2d)[] = [];
    if (parentNodes.length) {
      // recursively find masking on masked children
      for (const parentNode of parentNodes) {
        childNodes = childNodes.concat(this.getAllNodesMaskedByNode(parentNode));
      }
    }

    return [...parentNodes, ...childNodes];
  }

  getAllNodesContainingPipeOfNode(pipedNode: Node): Node[] {
    const nodes = this.getAllNodes();
    return nodes.filter(node => {
      return this.getAllPipesInNode(node).filter(node => node.ref === pipedNode.ref).length;
    });
  }

  getAllPipesInNode(node: Node): Node[] {
    const questionPipes = [...node.text.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]);

    let optionPipes = []
    if (node instanceof QuestionType1d) {
      //TODO: look through translations as well in event different (though rare) - also applies to validation checks
      const set1OptionText = node.getSet1Options(this).map(option => option.label).join(" ");
      optionPipes = [...set1OptionText.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]);

      if (node instanceof QuestionType2d) {
        const set2OptionText = node.getSet2Options(this).map(option => option.label).join(" ");
        optionPipes = optionPipes.concat([...set2OptionText.matchAll(QUESTION_CODE_REGEX)].map(match => match[1]));        }
    }
    return [...new Set([...questionPipes, ...optionPipes])]
      .map(pipedCode => this.getNodeByCode(parseInt(pipedCode)))
      .filter(node => node !== null);
  }

  hasNodeTypesBeforeIndex(index: number, nodeTypes: NodeTypeId[] = QUESTION_TYPES): boolean {
    return !!this.getAllNodes(nodeTypes, index).length;
  }

  getPageOfNode(node: Node): Page | null {
   return this.elements.filter(element => {
      return element instanceof Page && element.nodes.find(item => item.ref === node.ref);
    })?.[0] as Page ?? null
  }

  handleNodeTypeChange(nodeUpdated: Node) {
    // the node updated is excluded to avoid immediate errors, it'll be validated on change via builder watcher
    // or when clicking preview/publish
    this.validateNodes(false, nodeUpdated);
    this.validateLogic();
  }

  validateNodes(validateMaskedOptions = false, nodeTypeChange: Node = null, specificNode: Node = null, excludeNodes: Node[] = [], validateNodeCodes: boolean = true) {
    let nodes = this.getAllNodes();
    let codeChangeMap = null;

    if (!nodeTypeChange && validateNodeCodes) {
      codeChangeMap = new Map();

      // update codes
      nodes.forEach((question, index) => {
        if (question.code !== index + 1) {
          codeChangeMap.set(question.code, index + 1);
          question.code = index + 1;
          this.updateQuestionQuotaGroupName(question);
        }
      })
    }

    // update pipes, masking and options
    const excludeNodeRefs = excludeNodes.map(node => node.ref);
    specificNode = specificNode ? this.getNodeByRef(specificNode.ref) : null;
    nodes = specificNode
      ? [specificNode]
      : excludeNodes.length
        ? nodes.filter(node => !excludeNodeRefs.includes(node.ref))
        : nodes;

    nodes.forEach(node => {
      this.validateMasking(node, validateMaskedOptions);
      node.validate(this, codeChangeMap, true, !nodeTypeChange);

      if (node instanceof QuestionType1d) {
        const optionSet1 = node.getSet1(this);
        optionSet1.validate(this, node, codeChangeMap, true, !nodeTypeChange);

        if (node instanceof QuestionType2d) {
          const optionSet2 = node.getSet2(this);
          optionSet2.validate(this, node, codeChangeMap, true, !nodeTypeChange);
        }
      }
    })
  }

  //TODO: move validateMasking to QuestionType1d where other masking methods are
  validateMasking(childNode: Node, validateOptions = false) {
    this.resetSurveyErrors(null, childNode.ref, [SurveyErrorProperty.OptionMaskingSet1, SurveyErrorProperty.OptionMaskingSet2]);

    if (!(childNode instanceof QuestionType1d || childNode instanceof QuestionType2d)) {
      return;
    }

    // only masking to/from set1 supported for now and only one question
    const sets: OptionSetProperty[] = ['set1'];
    sets.forEach(set => {
      const maskedOptions = childNode.getMaskedOptionsBySet(set, this);
      const parentNodeRef = childNode.getMaskingBySet(set)?.[0].parentNode;
      const errorProperty = set === 'set1' ? SurveyErrorProperty.OptionMaskingSet1 : SurveyErrorProperty.OptionMaskingSet2;

      if (parentNodeRef) {
        const parentNode = this.getNodeByRef(parentNodeRef) as QuestionType1d;
        const parentCode = parentNode?.code ?? null;
        const childSetRef = childNode[set];

        if (parentCode === null) {
          // masking on non-existent question
          this.addSurveyError(new SurveyError(SurveyErrorType.NonExistant, errorProperty, childSetRef, childNode.ref));
        } else if (parentCode >= childNode.code || this.getPageOfNode(parentNode) === this.getPageOfNode(childNode)) {
          // masking parent is before child
          this.addSurveyError(new SurveyError(SurveyErrorType.Position, errorProperty, childSetRef, childNode.ref));
        }

        if (parentNode && validateOptions) {
          // ensure child options are set correctly on loading survey - these methods are triggered as changes
          // to options take place in editor otherwise

          const parentNodeOptionSet = this.getOptionSetByRef(parentNode[set]);
          const parentPlaceholderOption = parentNodeOptionSet.getPlaceholderOption();
          const optionSet = this.getOptionSetByRef(childNode[set]);

          for (let i = maskedOptions.length - 1; i >= 0; i--) {
            const option = maskedOptions[i];
            const parentRef = childNode.optionConfigurations[option.ref]?.parentRef ?? null;
            const optionExists = parentRef ? (parentNodeOptionSet.getOptionByRef(parentRef) ?? null) : null;

            if (!optionExists) {
              // parent option doesn't exist, so remove it
              childNode.updateMaskedChildOption(option, this, true, false);
              optionSet.deleteOptionByIndex(this, i, childNode, set);
            } else {
              // parent option does exist, so just ensure properties of children are correct (i.e. label, specify)
              childNode.updateMaskedChildOption(option, this, false, false);
            }
          }

          //TODO: should handle scenario of question doesn't have an option set?
          // (i.e. say question changed and removal interrupted). For now this will error.
          parentNodeOptionSet.options.forEach(parentOption => {
            if (parentOption !== parentPlaceholderOption) {
              parentNode.updateMaskedChildOption(parentOption, this);
            }
          })

          parentNode.updateMaskedChildOptionPositions(this, set);
        }
      }
    })
  }

  stripHTMLTags(html: string) {
    return html.replace(QUESTION_CODE_WITH_HTML_TAGS_REGEX, (match: string,
                                                             tag1: string,
                                                             tag2: string,
                                                             pipedCode: string,
                                                             tag3: string,
                                                             tag4: string,
                                                             tag5: string) => {
      const cleanPipedCode = pipedCode.replace(HTML_TAG_REGEX, '');
      if (match !== `{{${cleanPipedCode}}}`) {
        let openingTags = '', closingTags = '';
        const handleTag = (tag: string) => {
          if (tag === tag4 || tag === tag5) { // moves tag1, tag2 and tag 3 (if it's a closing tag) to the right side
            closingTags += tag;
          } else if (tag === tag1 || tag === tag2) { // moves tag5, tag4 and tag 3 (if it's an opening tag) to the left side
            openingTags += tag;
          }
        };
        [tag1, tag2, tag4, tag5].filter(tag => tag).forEach(handleTag);
        tag3?.split(HTML_TAG_REGEX).filter(tag => tag).forEach((tag) => {
          if (tag.includes('/')) {
            closingTags = tag + closingTags; //reversed accumulation
          } else {
            openingTags += tag;
          }
        })

        match = `${openingTags}{{${cleanPipedCode}}}${closingTags}`
      }
      return match;
    })
  }

  validatePipes(element: Node|Option, optionNode: Node = null, codeChangeMap: Map<number, number> = null) {
    const isNode = element instanceof Node;
    const node = isNode ? element : optionNode
    const errorProperty = isNode ? SurveyErrorProperty.NodePiping : SurveyErrorProperty.OptionPiping;
    const nonExistantPipes: string[] = [];
    const unsupportedPipes: string[] = [];
    const invalidPositionPipes: string[] = [];
    let invalidPipes: string;

    this.resetSurveyErrors(element.ref, node.ref, [errorProperty]);

    element[isNode ? 'text' : 'label'] = this.stripHTMLTags(element[isNode ? 'text' : 'label']);
    element[isNode ? 'text' : 'label'] = element[isNode ? 'text' : 'label'].replace(QUESTION_CODE_REGEX, (match, pipedCode) => {

      // update pipe to new position
      // TODO: hardcoding "Q" for now, but this will need a decision when we support multi-languages as updating would trigger a survey change:
      //  see: https://maruproduct.atlassian.net/browse/BUILDER-299
      const newCode = codeChangeMap?.size ? codeChangeMap.get(parseInt(pipedCode)) : pipedCode;
      if (newCode !== undefined) {
        match = `{{Q${newCode}}}`;
        pipedCode = newCode;
      }

      // check it's valid
      const pipedNode = this.getNodeByCode(parseInt(pipedCode));
      if (!pipedNode) {
        // check it exists, i.e. not deleted or manually manipulated
        // hardcode this one to Q# if int to ensure they don't add a question that then matches as might not be the intended pipe
        // and wouldn't want this to pass on subsequent checks without intention
        pipedCode = /^\d+$/.test(pipedCode) ? '#' : pipedCode;
        match = `{{Q${pipedCode}}}`;
        nonExistantPipes.push('Q' + pipedCode);
      } else if (!QUESTION_TYPES_SUPPORTED_PIPING.includes(pipedNode.type)) {
        // check the question type supports piping - only supported for 1D types for now
        unsupportedPipes.push('Q' + pipedCode);

      } else if (pipedCode >= node.code || this.getPageOfNode(node) === this.getPageOfNode(pipedNode)) {
        // check it's not before node is asked or on the same page
        invalidPositionPipes.push('Q' + pipedCode);
      }

      return match;
    });

    if (nonExistantPipes.length) {
      invalidPipes = [...new Set(nonExistantPipes)].join(', ');
      this.addSurveyError(new SurveyError(SurveyErrorType.NonExistant, errorProperty, element.ref, node.ref, { pipe: invalidPipes }));
    }

    if (unsupportedPipes.length) {
      invalidPipes = [...new Set(unsupportedPipes)].join(', ');
      this.addSurveyError(new SurveyError(SurveyErrorType.Invalid, errorProperty, element.ref, node.ref, { pipe: invalidPipes }));
    }

    if (invalidPositionPipes.length) {
      invalidPipes = [...new Set(invalidPositionPipes)].join(', ');
      this.addSurveyError(new SurveyError(SurveyErrorType.Position, errorProperty, element.ref, node.ref, { pipe: invalidPipes }));
    }
  }

  replaceQuestionPipesWithGenericInsert(question: QuestionType1d | QuestionType2d) {
    question.text = question.text.replace(QUESTION_CODE_REGEX, i18n.global.t('question.questionPipeGenericInsert'));
    const options = [...question.getSet1Options(this), ...(question instanceof QuestionType2d ? question.getSet2Options(this) : [])];
    options.forEach(option => option.label = option.label.replace(QUESTION_CODE_REGEX, i18n.global.t('question.questionPipeGenericInsert')));
  }

  moveNode(node: Node, fromPage: Page, toPage: Page, toIndex: number) {
    const fromIndex = fromPage.getNodeIndex(node);
    if (fromIndex > -1) {
      const fromPageLockedItems = Randomization.getLockedItems(fromPage);
      const toPageLockedItems = Randomization.getLockedItems(toPage);
      fromPage.nodes.splice(fromIndex, 1);
      toPage.nodes.splice(toIndex, 0, node);
      if (fromPage.nodeRandomization.hasRandomizedItems()) {
        Randomization.setRandomization(fromPage, fromPageLockedItems);
      }
      if (toPage.nodeRandomization.hasRandomizedItems()) {
        Randomization.setRandomization(toPage, toPageLockedItems);
      }
    }
  }

  importNode(
    node: Node,
    source: Survey,
    page: Page = null,
    pageIndex: number = null,
    nodeIndex = null,
    importQuotaGroup: boolean = true,
    importMasking: boolean = false,
    importPiping: boolean = false,
    duplicateRefs: boolean = false,
  ): Node {
    const isExistingPage = page instanceof Page && this.getElementByRef(page.ref);
    page = isExistingPage ? page : new Page({survey: this})
    node = page.addNode(node, isExistingPage ? nodeIndex : null, this, true, source, importQuotaGroup, importMasking, importPiping, duplicateRefs);

    if (!isExistingPage) {
      this.addElement(page, pageIndex);
    }

    return node;
  }

  getNodeAssets(node: Node): Array<Asset> {
    const assets = [] as Array<Asset>

    assets.push(...node.assets)
    const sets = this.getOptionSetsByNode(node)
    sets.forEach((set: OptionSet) => assets.push(...set.getAssets()))

    return assets
  }

  replaceAssets(newAssets: Array<MapAssets>, sources: Array<Element> = []): void {
    const elements = sources.length > 0 ? sources : this.elements
    const pages = elements.filter((element: Element) => element instanceof Page)
    const nodes = pages
      .flatMap((page: Page) => page.nodes)
      .filter((node: Node) =>
        this.getNodeAssets(node)
          .some((asset: Asset) =>
            newAssets.some((newAsset: MapAssets) => newAsset.sourceRef === asset.ref)
          )
      );

    const sets: Array<OptionSet> = []
    let assets: Array<Asset> = []
    let oldAsset, newAsset: Asset
    nodes.forEach((node: Node) => {
      assets = []
      newAssets.forEach((mapAsset: MapAssets) => {
        oldAsset = node.assets.find((asset: Asset) => mapAsset.sourceRef === asset.ref)
        if (oldAsset) {
          assets.push({...mapAsset.asset, ...{type: oldAsset.type}})
          node.text = node.text.replace(`src="${oldAsset.url}"`, `src="${mapAsset.asset.url}"`)
        } else {
          sets.push(...this.getOptionSetsByNode(node))
        }
      })
      node.assets = assets
    })
    sets
      .filter((set: OptionSet, index, array) => array.findIndex((s: OptionSet) => s.ref === set.ref) === index) // unique
      .forEach((set: OptionSet) => {
        set.options.forEach((option: Option) => {
          newAsset = newAssets.find((mapAsset: MapAssets) => mapAsset.sourceRef === option.asset?.ref)?.asset
          if (newAsset) {
            option.label = option.label.replace(`src="${option.asset.url}"`, `src="${newAsset.url}"`)
            option.asset = {...newAsset, ...{type: option.asset.type}}
          }
        })
      })
  }

  /*********************************************************************************************************************/
  /* ELEMENTS - RULE SETS */
  /*********************************************************************************************************************/

  deleteRuleSet(ruleSet: RuleSet) {
    const index = this.getElementIndex(ruleSet);
    if (index > -1) {
      this.elements.splice(index, 1);
    }

    this.resetSurveyErrors(null, ruleSet.ref);

    this.handleElementPositionChange();
  }

  getAllRuleSets(sliceBeforeIndex: number | null = null, sliceAfterIndex: number | null = null): RuleSet[] {
    return this.elements.filter(element =>
      element instanceof RuleSet
      && (sliceBeforeIndex !== null ? this.elements.indexOf(element) < sliceBeforeIndex : true)
      && (sliceAfterIndex  !== null ? this.elements.indexOf(element) > sliceAfterIndex  : true)
    ) as RuleSet[];
  }

  getAllRuleSetRulesContainingQuestion(question: Node): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingQuestion: RuleSet[] = [];

    const doRulesContainQuestion = (rules: (Rule | RuleGroup)[], question: Node): boolean => {
      for (const rule of rules) {
        if (rule instanceof Rule && rule.node === question.ref) {
          return true;
        }
        // check rule groups for grids
        if (rule instanceof RuleGroup && doRulesContainQuestion(rule.rules, question)) {
          return true;
        }
      }
      return false;
    };

    ruleSets.forEach(ruleSet => {
      const rulesContainQuestion = doRulesContainQuestion(ruleSet.ruleGroup.rules, question);
      if (rulesContainQuestion) {
        ruleSetsContainingQuestion.push(ruleSet);
      }
    });

    return ruleSetsContainingQuestion;
  }

  getAllRuleSetGoTosContainingPage(page: Page): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingPage: RuleSet[] = [];

    ruleSets.forEach(ruleSet => {
      const goToContainsPage = (ruleSet.goToType === RuleSetGoToType.Skip && ruleSet.goTo === page.ref);
      if (goToContainsPage) {
        ruleSetsContainingPage.push(ruleSet);
      }
    });

    return ruleSetsContainingPage;
  }

  getAllRuleSetElseGoTosContainingPage(page: Page): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingPage: RuleSet[] = [];

    ruleSets.forEach(ruleSet => {
      const elseGoToContainsPage = (ruleSet.elseGoToType === RuleSetGoToType.Skip && ruleSet.elseGoTo === page.ref);
      if (elseGoToContainsPage) {
        ruleSetsContainingPage.push(ruleSet);
      }
    });

    return ruleSetsContainingPage;
  }

  getAllRuleSetsContainingOption(option: Option): RuleSet[] {
    const ruleSets = this.getAllRuleSets();
    const ruleSetsContainingOption: Set<RuleSet> = new Set();

    const doRulesContainOption = (rules: (Rule | RuleGroup)[], option: Option): boolean => {
      for (const rule of rules) {
        const value = rule instanceof Rule ? (rule as Rule).value as RuleChoiceValue : null;
        let containsOption = false;
        if (value) {
          containsOption = ['set1', 'set2'].some(set => Array.isArray(value[set]) ? value[set].includes(option.ref) : value[set] === option.ref);
        } else if (rule instanceof RuleGroup) {
          // check if option is in rule group
          containsOption = doRulesContainOption(rule.rules, option);
        }
        if (containsOption) {
          return true;
        }
      }
      return false;
    }

    ruleSets.forEach(ruleSet => {
      if (doRulesContainOption(ruleSet.ruleGroup.rules, option)) {
        ruleSetsContainingOption.add(ruleSet);
      }
    })

    return Array.from(ruleSetsContainingOption);
  }

  getAllRuleSetsContainingOptionSet(optionSet: OptionSet): RuleSet[] {
    const options = optionSet.options;
    return Array.from(new Set(options.flatMap(option => this.getAllRuleSetsContainingOption(option))));
  }

  validateLogicCodes() {
    // just ensure they're numbered sequentially; no dependant logic to update
    const ruleSets = this.getAllRuleSets();
    ruleSets.forEach((ruleSet, index) => {
      ruleSet.code = index + 1;
    })
  }

  validateLogic(rulesOnly = false, specificRuleSet: RuleSet = null, excludeRuleSets: RuleSet[] = [], specificRule: Rule = null) {
    if (specificRule) {
      this.resetSurveyErrors(specificRule.ref)
    } else if (specificRuleSet) {
      this.resetSurveyErrors(null, specificRuleSet.ref);
    } else {
      this.resetSurveyErrors(null, null, RULE_SET_ERROR_PROPERTIES);
    }

    this.validateLogicCodes();

    const excludeRuleSetRefs = excludeRuleSets.map(ruleSet => ruleSet.ref);
    specificRuleSet = specificRuleSet ? this.getElementByRef(specificRuleSet.ref) as RuleSet: null;
    const ruleSets = specificRuleSet
      ? [specificRuleSet]
      : excludeRuleSets.length
        ? this.getAllRuleSets().filter(ruleSet => !excludeRuleSetRefs.includes(ruleSet.ref))
        : this.getAllRuleSets();

    ruleSets.forEach(ruleSet => {
      const ruleSetIndex = this.getElementIndex(ruleSet);

      // validate conditions
      const rules = specificRule ? [specificRule] : ruleSet.ruleGroup.rules;
      rules.forEach((rule, index) => {
        if (rule instanceof Rule) {
          this.validateLogicRule(rule, ruleSet, ruleSetIndex);
        } else if (rule instanceof RuleGroup) {
          for (let ruleOfRuleIndex = 0; ruleOfRuleIndex < ((rule as RuleGroup).rules as Rule[]).length; ruleOfRuleIndex++) {
            const ruleOfRule = ((rule as RuleGroup).rules as Rule[])[ruleOfRuleIndex];

            this.validateLogicRule(ruleOfRule, ruleSet, ruleSetIndex, ruleOfRuleIndex);

            const node = ruleOfRule.node ? this.getNodeByRef(ruleOfRule.node) : null;
            if (node === null || !QUESTION_2D_TYPES.includes(node.type)) {
              // grid node was deleted or changed type, reset to rule
              ruleSet.ruleGroup.convertGridGroupToRule(rule, index, this);
              break;
            }
          }
        }
      })

      // validate goTo pages
      if (!rulesOnly) {
        this.validateLogicGoTo('goTo', ruleSet, ruleSetIndex);
        this.validateLogicGoTo('elseGoTo', ruleSet, ruleSetIndex);
      }
    })
  }

  //TODO: move these ruleset validations to the rule/ruleset classes
  validateLogicRule(rule: Rule, ruleSet: RuleSet, ruleSetIndex: number, gridRuleIndex: number = null) {
    const originalNode = rule.node;

    this.resetSurveyErrors(rule.ref);

    rule.validate(this, rule.value);

    const node = rule.node ? this.getNodeByRef(rule.node) : null;
    const errorProperty = SurveyErrorProperty.RuleNode;

    if (gridRuleIndex === null || gridRuleIndex === 0) {
      // node checks only needed on first rule in grid group - don't want to repeat the errors for each rule
      if (node) {
        // validate position
        const page = this.getPageOfNode(node);

        if (this.getElementIndex(page) > ruleSetIndex) {
          this.addSurveyError(new SurveyError(SurveyErrorType.Position, errorProperty, rule.ref, ruleSet.ref));
        }

        // validate type
        if (!QUESTION_TYPES_SUPPORTED_ROUTING.includes(node.type)) {
          this.addSurveyError(new SurveyError(SurveyErrorType.Invalid, errorProperty, rule.ref, ruleSet.ref));
        }

      } else if (originalNode) {
        // node was deleted
        this.addSurveyError(new SurveyError(SurveyErrorType.NonExistant, errorProperty, rule.ref, ruleSet.ref));
      }
    }

    // validate completeness
    const errorType = SurveyErrorType.Empty;
    if (!rule.isRuleNodeComplete()) {
      this.addSurveyError(new SurveyError(errorType, SurveyErrorProperty.RuleNode, rule.ref, ruleSet.ref));
    }

    if (!rule.isRuleOperatorComplete()) {
      this.addSurveyError(new SurveyError(errorType, SurveyErrorProperty.RuleOperator, rule.ref, ruleSet.ref));
    }

    if (node instanceof QuestionType1d) {
      if (!rule.isRuleValueSetComplete('set1', node)) {
        this.addSurveyError(new SurveyError(errorType, SurveyErrorProperty.RuleValueSet1, rule.ref, ruleSet.ref));
      }
      if (node instanceof QuestionType2d && !rule.isRuleValueSetComplete('set2', node)) {
        this.addSurveyError(new SurveyError(errorType, SurveyErrorProperty.RuleValueSet2, rule.ref, ruleSet.ref));
      }
    } else {
      if (!rule.isRuleValueComplete()) {
        this.addSurveyError(new SurveyError(errorType, SurveyErrorProperty.RuleValue, rule.ref, ruleSet.ref));
      }
    }
  }

  validateLogicGoTo(property: RuleSetGoToProperty, ruleSet: RuleSet, ruleSetIndex: number = null) {
    ruleSetIndex = ruleSetIndex ?? this.getElementIndex(ruleSet);

    const errorProperty = property === 'goTo' ? SurveyErrorProperty.RuleSetGoTo : SurveyErrorProperty.RuleSetElseGoTo;
    this.resetSurveyErrors(null, ruleSet.ref, [errorProperty]);

    // update type first, as needed
    if (property === 'elseGoTo') {
      ruleSet.updateElseGoToType();
    } else {
      ruleSet.updateGoToType();
    }

    if (property === 'elseGoTo' && !ruleSet.elseGoTo) {
      // update elseGoTo to next question if is one, else to complete status
      const nextPageRef = ruleSet.getNextPageRef(this);
      if (nextPageRef) {
        ruleSet.elseGoTo = nextPageRef;
        ruleSet.elseGoToType = RuleSetGoToType.Skip;
      } else {
        ruleSet.elseGoTo = RuleSetGoToStatus.Complete;
        ruleSet.elseGoToType = RuleSetGoToType.Terminate;
      }
    } else if (ruleSet[property + 'Type'] === RuleSetGoToType.Skip && ruleSet[property] !== null) {
      // validate question position
      const page = this.getElementByRef(ruleSet[property]);
      if (page && ruleSetIndex > this.getElementIndex(page)) {
        this.addSurveyError(new SurveyError(SurveyErrorType.Position, property === 'goTo' ? SurveyErrorProperty.RuleSetGoTo : SurveyErrorProperty.RuleSetElseGoTo, ruleSet.ref));
      } else if (!page) {
        ruleSet[property] = null;
      }
    }

    if (ruleSet[property] === null) {
      this.addSurveyError(new SurveyError(SurveyErrorType.Empty, property === 'goTo' ? SurveyErrorProperty.RuleSetGoTo : SurveyErrorProperty.RuleSetElseGoTo, ruleSet.ref));
    }
  }

  validateLogicElseGoToOnChange(action: SurveyChangeAction, element: Element, elementMovingBeforeOldIndex: Page | RuleSet = null, elementMovingAfterOldIndex: Page | RuleSet = null) {

    const elementIndex = this.getElementIndex(element);
    if (element instanceof RuleSet && action === SurveyChangeAction.AddElement) {
      // just need to auto set elseGoTo
      this.validateLogicGoTo('elseGoTo', element as RuleSet, elementIndex);
    }

    if (action !== SurveyChangeAction.MoveElement && (elementIndex === 0 || element instanceof RuleSet)) {
      return;
    }

    let previousRuleSets = this.getPreviousElementsByType(element, ElementType.RuleSet) as RuleSet[];
    let nextPage = this.getNextElementByType(element, ElementType.Page) as Page;
    const updatedElseGoToRules = []

    const addMoveAction = () => {
      // if page that was added or moved is after a logic where elseGoTo is the next page, set to element (new next page)
      // or is no next page and logic is complete status, set to element
      if (nextPage instanceof Page || nextPage === null) {
        previousRuleSets.forEach(ruleSet => {
          const lastElementWasCompleteLogic = nextPage === null && ruleSet.elseGoTo === RuleSetGoToStatus.Complete;
          if (lastElementWasCompleteLogic || (nextPage && ruleSet.elseGoTo === nextPage.ref)) {
            const prevElseGoTo = ruleSet.elseGoTo
            ruleSet.elseGoTo = element.ref;
            ruleSet.elseGoToType = RuleSetGoToType.Skip;
            updatedElseGoToRules.push(ruleSet)
          }
        })
      }
    }

    switch(action) {
      case SurveyChangeAction.MoveElement:
        if (element instanceof RuleSet) {
          const prevElseGoTo = element.elseGoTo
          const oldNextPage = elementMovingAfterOldIndex instanceof Page ? elementMovingAfterOldIndex :
            elementMovingAfterOldIndex ? this.getNextElementByType(elementMovingAfterOldIndex, ElementType.Page) : null;
          const isSkipToNextPage = element.elseGoToType === RuleSetGoToType.Skip && oldNextPage && element.elseGoTo === oldNextPage.ref;
          const isLastLogicAndSkip = element.elseGoToType === RuleSetGoToType.Skip && nextPage === null;
          const wasLastLogicAndComplete = element.elseGoTo === RuleSetGoToStatus.Complete && oldNextPage === null;

          // if was a skip and set to the next page, update to new next page
          // if is the last logic, update to complete if already a complete type (i.e. don't update term)
          // if was the last logic, update to next page if there is one, else complete
          if (isSkipToNextPage || isLastLogicAndSkip || wasLastLogicAndComplete) {
            element.elseGoTo = nextPage?.ref ?? RuleSetGoToStatus.Complete;
            element.elseGoToType = nextPage?.ref ? RuleSetGoToType.Skip : RuleSetGoToType.Terminate;
          }
        } else if (element instanceof Page) {
          // if new position is after a logic
          addMoveAction();

          // if old position was after a logic
          if (elementMovingBeforeOldIndex instanceof RuleSet) {
            nextPage = this.getNextElementByType(elementMovingBeforeOldIndex, ElementType.Page) as Page;
            previousRuleSets = this.getPreviousElementsByType(elementMovingBeforeOldIndex, ElementType.RuleSet, true) as RuleSet[];
            previousRuleSets.forEach(ruleSet => {
              if (ruleSet.elseGoTo === element.ref) {
                // update to next page or complete if none
                ruleSet.elseGoTo = nextPage?.ref ?? RuleSetGoToStatus.Complete;
                ruleSet.elseGoToType = nextPage?.ref ? RuleSetGoToType.Skip : RuleSetGoToType.Terminate;
                updatedElseGoToRules.push(ruleSet)
              }
            })
          }
        }

        break;

      case SurveyChangeAction.AddElement:
        // if page was added after a logic
        addMoveAction();
        break;
      case SurveyChangeAction.DeleteElement:
        // if page that will be deleted is after a logic
        previousRuleSets.forEach(ruleSet => {
          const willLastElementBeSkipLogic = nextPage === null && ruleSet.elseGoToType !== RuleSetGoToType.Terminate;
          if (willLastElementBeSkipLogic || ruleSet.elseGoTo === element.ref) {
            // update to next page or complete if last element
            ruleSet.elseGoTo = willLastElementBeSkipLogic ? RuleSetGoToStatus.Complete : nextPage.ref;
            ruleSet.elseGoToType = willLastElementBeSkipLogic ? RuleSetGoToType.Terminate : RuleSetGoToType.Skip;
          }
        })
        break;
      default:
        break;
    }

    if (updatedElseGoToRules.length) {
      const rulesCode = updatedElseGoToRules.map(ruleset => ruleset.getCodeLabel() || ruleset.name).filter(label => label)
      if(rulesCode.length) {
        webBridge.sendError(`${t('question.logic.routing.goTo.logicsUpdated')} ${rulesCode.reverse().join(', ')}`)
      }
    }
  }

  /*********************************************************************************************************************/
  /* QUOTAS */
  /*********************************************************************************************************************/

  addQuota(quota: Quota | QuotaGroup, index: number = null): Quota | QuotaGroup {
    if (index !== null && typeof this.quotas[index] !== 'undefined') {
      this.quotas.splice(index, 0, quota);
    } else {
      this.quotas.push(quota);
    }
    return quota;
  }

  addQuotas(quotas: Array<Quota | QuotaGroup>): Array<Quota | QuotaGroup> {
    quotas.forEach(quota => {
      if (!this.getQuotaByRef(quota.ref)) {
        if (quota instanceof QuotaGroup || this.isObjectQuotaGroup(quota)) {
          this.quotas.push(
            new QuotaGroup(
              this,
              (quota as QuotaGroup).ref ?? null,
              (quota as QuotaGroup).name ?? null,
              (quota as QuotaGroup).quotas ?? null
            )
          )
        } else {
          this.quotas.push(
            new Quota(
              this,
              quota.ref ?? null,
              quota.name ?? null,
              quota.rule ?? null,
              quota.count ?? null,
            )
          )
        }
      }
    })

    return this.quotas;
  }

  addQuestionQuotaGroup(question: QuestionType1d, quotaGroup: QuotaGroup = null, duplicateOptionSetMap: Map<string, string> = null): QuotaGroup {
    const set = question.getSet1(this);
    const duplicate = quotaGroup && !!duplicateOptionSetMap?.size;
    const newQuotaGroup = duplicate || !quotaGroup ? new QuotaGroup(this) : quotaGroup;

    question.quotaGroupRef = newQuotaGroup.ref;

    if (!quotaGroup || duplicate) {
      set.options.forEach((option: Option) => {
        let count = 0;
        if (duplicate) {
          const duplicateRef = duplicateOptionSetMap.get(option.ref);
          const quota = quotaGroup.quotas.find(quota => ((quota.rule as Rule).value as RuleChoiceValue)?.set1 === duplicateRef);
          count = quota?.count ?? 0;
        }
        this.addOptionQuotaToQuestionQuotaGroup(option, question, null, newQuotaGroup, count);
      })
    }

    this.updateQuestionQuotaGroupName(question, newQuotaGroup);

    if (!this.getQuotaByRef(newQuotaGroup.ref)) {
      this.addQuota(newQuotaGroup);
    }

    // ensure existing/duplicated quota group is valid
    question.validateQuestionQuotaGroup(this);

    return newQuotaGroup;
  }

  addOptionQuotaToQuestionQuotaGroup(option: Option, question: QuestionType1d, index = null, quotaGroup: QuotaGroup = null, count: number = null): Quota | null {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const isPlaceholder = question.getSet1(this).getPlaceholderOption() === option;
      let quota = this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option);
      if (!quota && !isPlaceholder) {
        const ruleValue = { set1: option.ref } as RuleChoiceValue;
        const rule = new Rule(this, null, question.ref, ruleValue, RuleOperator.EqualsAny, false);
        quota = new Quota(this, null, stripHTML(option.label), rule, count ?? 0);
        quotaGroup.addQuota(quota, this, index);
      }
      return quota;
    }
    return null;
  }

  updateOptionQuotaInQuestionQuotaGroup(option: Option, question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    const optionQuota = quotaGroup ? this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option) : null;
    if (optionQuota) {
      optionQuota.name = stripHTML(option.label);
    }
  }

  updateQuestionQuotaGroupName(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const cleanName = question.getCleanName(false, true);
      quotaGroup.name = cleanName?.length ? cleanName : question.getCodeLabel();
    }
  }

  updateQuestionQuotaGroupQuotaPositions(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      const options = this.getOptionSetByRef((question as QuestionType1d).set1).options;
      quotaGroup.quotas = options.map(option => this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option)).filter(Boolean);
    }
  }

  deleteQuota(quota: Quota | QuotaGroup) {
    const index = this.getQuotaIndex(quota);
    if (index > -1) {
      this.quotas.splice(index, 1);
    }
  }

  deleteQuestionQuotaGroup(question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    if (quotaGroup) {
      (question as QuestionType1d).quotaGroupRef = null;
      this.deleteQuota(quotaGroup);
    }
  }

  deleteOptionQuotaInQuestionQuotaGroup(option: Option, question: Node, quotaGroup: QuotaGroup = null) {
    quotaGroup = quotaGroup ?? this.getQuestionQuotaGroup(question);
    const optionQuota = quotaGroup ? this.getOptionQuotaInQuestionQuotaGroup(quotaGroup, option) : null;
    if (optionQuota) {
      quotaGroup.deleteQuota(optionQuota);
    }
  }

  getQuotaByRef(ref: string): Quota | QuotaGroup | null {
    return this.quotas.find(el => el.ref === ref ?? null);
  }

  getQuotaIndex(quota: Quota|QuotaGroup): number {
    return this.getQuotaIndexByRef(quota.ref);
  }

  getQuotaIndexByRef(ref: string): number {
    return this.quotas.findIndex(el => el.ref === ref);
  }

  getQuotaGroupQuestion(quotaGroup: QuotaGroup): QuestionType1d | undefined {
    return this.getAllNodes(QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS).find((node: QuestionType1d) => {
      return node.quotaGroupRef === quotaGroup.ref
    }) as QuestionType1d;
  }

  getOptionQuotaInQuestionQuotaGroup(quotaGroup: QuotaGroup, option: Option): Quota {
    return quotaGroup.quotas.find(quota => ((quota.rule as Rule).value as RuleChoiceValue).set1 === option.ref);
  }

  getQuestionQuotaGroup(question: Node): QuotaGroup | null {
    if (!QUESTION_TYPES_SUPPORTED_QUESTION_QUOTA_GROUPS.includes(question.type)) {
      return null;
    }

    return (question as QuestionType1d).getQuestionQuotaGroup(this);
  }

  isObjectQuotaGroup(quotaGroupObject: any): boolean {
    const objProps = Object.keys(quotaGroupObject);
    const classInstance = new QuotaGroup(new Survey());
    const classProperties = Object.keys(classInstance);

    return objProps.length === classProperties.length && objProps.every(prop => classProperties.includes(prop));
  }

  /*********************************************************************************************************************/
  /* TRANSLATIONS */
  /*********************************************************************************************************************/

  addLocales(locales: Array<Locale>) {
    const addedLocales = this.getAddedLocaleIds();
    locales.forEach(locale => {
      if (!addedLocales.includes(locale.ISO15897Code)) {
        this.addTranslations(this.getTranslationData(locale));
      }
    })
  }

  addActiveLocale(locale: string|Locale) {
    locale = (locale instanceof Locale ? locale : new Locale(locale)).ISO15897Code;
    if (!this.locales.includes(locale)) {
      this.locales.push(locale);
    }
  }

  removeActiveLocale(locale: string|Locale) {
    locale = (locale instanceof Locale ? locale : new Locale(locale)).ISO15897Code;
    this.locales = this.locales.filter(activeLocale => activeLocale !== locale);
  }

  toggleActiveLocale(locale: string|Locale, isActive: boolean) {
    if (isActive) {
      this.addActiveLocale(locale);
    } else {
      this.removeActiveLocale(locale);
    }
  }

  addTranslations(data: TranslationData) {
    data.array.forEach(translation => {
      const [, ref, _, translatedTxt] = translation,
        nodeOrOption: Node | Option = this.getNodeByRef(ref) || this.getOptionByRef(ref);

      nodeOrOption.addTranslation(data.targetLocale.ISO15897Code, translatedTxt);
    })
  }

  addTranslationsFromFile(file: TranslationFile) {
    this.addTranslations(file.data)
  }

  getTranslationData(targetLocale: Locale = Locale.default): TranslationData {
    //TODO: include page titles when exposed in UI
    const data = [];

    this.getAllNodes().forEach(node => {
      if (!node?.text?.length) {
        // Don't add empty nodes
        return;
      }

      data.push({
        'context': `Q${node.code}`,
        'id': node.ref,
        'original-text': node.text,
        'translated-text': (node?.i18n && node.i18n[targetLocale.ISO15897Code]) ?? null,
      });

      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          if (!option.label.length) {
            // Don't add empty options
            return;
          }

          const existingTranslation = data.find(translation => translation.id === option.ref);
          if (existingTranslation) {
            // Add this context to existing translation record
            existingTranslation.context += `, Q${node.code} - ${index ? 'Column' : 'Row'} ${option.code}`;
            return;
          }

          data.push({
            'context': `Q${node.code} - ${index ? 'Column' : 'Row'} ${option.code}`,
            'id': option.ref,
            'original-text': option.label,
            'translated-text': (option.i18n && option.i18n[targetLocale.ISO15897Code]) ?? null,
          })
        })
      })
    })

    return new TranslationData(data, targetLocale);
  }

  getAddedLocaleIds(): Array<string> {
    return this.getAllNodes().reduce((locales: string[], node) => {
      let setLocales = [];
      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          if (!option.i18n) {
            return;
          }

          setLocales = [...setLocales, ...Object.keys(option.i18n)]
        });
      });

      return [...locales, ...Object.keys(node.i18n), ...setLocales]
        .filter((value, index, array) => array.indexOf(value) === index);
    }, []);
  }

  getAddedLocales(): Array<Locale> {
    return this.getAddedLocaleIds().map(locale => new Locale(locale));
  }

  deleteTranslationsFor(locale: Locale) {
    if (this.locale === locale.ISO15897Code) {
      throw new CannotDeleteDefaultLocaleTranslationsError(locale);
    }

    this.getAllNodes().forEach(node => {
      [QuestionType1d, QuestionType2d].forEach((nodeType, index) => {
        if (!(node instanceof nodeType)) {
          return;
        }

        const set = this.getOptionSetByRef(node[`set${index + 1}`]);
        set.options.forEach((option: Option) => {
          delete option.i18n[locale.ISO15897Code];
        })
      });

      delete node.i18n[locale.ISO15897Code];
    })

    if (this.locales.includes(locale.ISO15897Code)) {
      // Remove locale from locales array
      this.locales.splice(this.locales.indexOf(locale.ISO15897Code), 1);
    }
  }

  /*********************************************************************************************************************/
  /* ERRORS */
  /*********************************************************************************************************************/

  addSurveyError(error: SurveyError) {
    this.errors.push(error);
  }

  resetSurveyErrors(errorItemRef: Ref = null, errorEditElementRef: Ref = null, errorProperties: SurveyErrorProperty[] = null, errorTypes: SurveyErrorType[] = null) {
    const resetAll = errorItemRef === null && errorEditElementRef === null && errorProperties === null && errorTypes === null;
    this.errors = resetAll
      ? []
      : this.filterSurveyErrors(errorProperties, errorItemRef, errorEditElementRef, errorTypes, true) as SurveyError[];
  }

  filterSurveyErrors(
    errorProperties: SurveyErrorProperty[] = null,
    errorItemRef: Ref = null,
    errorEditElementRef: Ref = null,
    errorTypes: SurveyErrorType[] = null,
    negate: boolean = false,
    messagesOnly: boolean = false,
    propertiesOnly: boolean = false
  ): SurveyError[] | string[] | SurveyErrorProperty[] {

    let errors: SurveyError[];

    if (errorProperties === null && errorItemRef === null && errorEditElementRef === null && errorTypes === null) {
      errors = this.errors;
    } else {
      errors = this.errors.filter(error => {
        const matchItemRef = errorItemRef === null || error.errorItemRef === errorItemRef;
        const matchEditElementRef = errorEditElementRef === null || error.errorEditElementRef === errorEditElementRef;
        const matchProperty = errorProperties === null || errorProperties.includes(error.errorProperty);
        const matchType = errorTypes === null || errorTypes.includes(error.errorType);

        if (negate) {
          // exclude if error doesn't match all conditions
          return !matchItemRef || !matchEditElementRef || !matchProperty || !matchType;
        }

        return matchItemRef && matchEditElementRef && matchProperty && matchType;
      });
    }

    return messagesOnly
      ? errors.map(error => error.message)
      : propertiesOnly
        ? errors.map(error => error.errorProperty).sort()
        : errors;
  }
}
