export default {
  surveyRail: {
    header: {
      questionList: 'Questions',
      import: 'Import',
      settings: 'Settings'
    },
    actions: {
      closeRail: 'Close Question List',
      openRail: 'Open Question List',
      expandRail: 'Expand Question List',
      collapseRail: 'Collapse Question List',
      expandPage: 'Expand Page',
      collapsePage: 'Collapse Page',
      addItems: {
        menuTitle: 'Add to survey',
        node: 'Add Question',
        aiNode: 'Use AI to Create Question',
        page: 'Add Page',
        ruleSet: 'Add Logic',
        block: 'Add Block'
      },
    },
    import: {
      noSurveys: 'There are no surveys available.',
      deleteImportedElement: 'Undo Import',
      back: 'Back to list',
      currentSurvey: 'Current Survey',
      noLogicAlert: 'Please note that importing logic, piping and carry forward is not available at the moment.',
    }
  }
}
