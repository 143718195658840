export default {
  actions: {
    add: 'Add',
    cancel: 'Cancel',
    apply: 'Apply',
    save: 'Save',
    saveChanges: 'Save Changes',
    delete: 'Delete',
    update: 'Update',
    replace: 'Replace',
    move: 'Move',
    decrease: 'Decrease',
    increase: 'Increase',
    none: 'None',
    select: 'Select',
    piping: 'Piping',
    chooseFromList: 'Choose from list',
    selectQuestion: 'Select Question',
    pin: 'Pin',
    openEndAnswer: 'Open End Answer',
    exclusive: 'Exclusive',
    generate: 'Generate',
    tryAgain: 'Try Again',
    duplicate: 'Duplicate'
  },
  labels: {
    error: 'Error'
  }
}
